#audience-based-search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
    label {
        margin-right: 10px;
    }

    select {
        padding: 5px;
    }

    .Button {
        background-color: #eee;
        border: 1px solid #000;
        border-radius: 3px;
        color: #000;
        display: block;
        padding: 5px;
        text-decoration: none;
    }
}